<template>
  <div class="text-container">
    Sicherlich fragen Sie sich auch täglich, wie kann ich es schaffen, den
    Apothekenbetrieb optimal zu gestalten, ständig der hohen Dynamik am Markt
    gerecht zu werden und die persönliche Work-Life-Balance zu erhalten. Gerne
    teilen wir mit Ihnen unsere Erfolgsfaktoren und unsere Erkenntnisse, die wir
    aus eigenen Fehlern gezogen haben.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie bei der optimalen Gestaltung Ihres Apothekenbetriebs. Profitieren Sie von Erkenntnissen, die wir für Sie zusammenfassen.",
      },
    ],
  },
};
</script>